/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import { ImCross } from 'react-icons/im';

import iconBussTraslado from '../../assets/icon-buss-traslado.svg';
import iconBussTowerTraslado from '../../assets/icon-buss-tower-traslado.svg';
import iconTwoBussTraslado from '../../assets/icon-two-buss-traslado.svg';
import transporteOficialPlanalto from '../../assets/logo_brocker_turismo_svg.svg';

import Head from '@alweb-merkator/shared/components/Head';
import {
  Styles,
  Secao,
  Tradutor,
  SecaoMenu,
  SecaoCabecalho,
  SecaoTitulo,
  SecaoMenuNav,
  SecaoMenuItem,
  SecaoMenuLista,
  SecaoBotaoVoltar,
} from './styles';

import { Modal, ModalBody, VideoStyled, ButtonClose } from './styles_modal';

const Traslado = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const linkWhatsCompra =
    'https://eventos.brockerturismo.com.br/eventos/zero-grau';
  const [modal, setModal] = useState(false);
  const toggle = () => {
    // setModal(!modal);
    window.location.replace('/');
  };

  function handleShow() {
    setModal(true);
  }

  // console.log(modal);

  return (
    <div className="pagina-traslado">
      <Styles />
      <Head
        title={t('paginas.traslado.titulo')}
        description={t('paginas.traslado.titulo')}
      />
      <Secao className="mb-4">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <Tradutor path="paginas.traslado.titulo" />
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-5 text-justify">
            <div
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.description'),
              }}
            ></div>
            <p className="align-center">
              <p
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textTrasnporteOficial'),
                }}
              ></p>
              <img
                src={transporteOficialPlanalto}
                alt="Transporte Planalto"
                className="max-height-60"
              />
            </p>
          </div>
          <div className="col-sm-3">
            <a
              href={linkWhatsCompra}
              target="_blank"
              className="btn btn-block btn-secondary"
              rel="noreferrer"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textBotaoComprar'),
                }}
              ></div>
            </a>
            <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Domingo`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado no domingo dia 20/11/2023"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaUm'),
                }}
              ></div>
            </HashLink>
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Terca`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na terça-feira dia 22/11/2022"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaTres'),
                }}
              ></div>
            </HashLink> */}
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Quinta`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na quinta-feira dia 24/11/2022"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaCinco'),
                }}
              ></div>
            </HashLink> */}
          </div>
          <div className="col-sm-4">
            <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Valores`}
              className="btn btn-block btn-secondary"
              title="Veja os valores dos traslados"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textBotaoValores'),
                }}
              ></div>
            </HashLink>
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Segunda`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na segunda-feira dia 21/11/2022"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaDois'),
                }}
              ></div>
            </HashLink> */}
            <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Quarta`}
              className="btn btn-block btn-primary"
              title="Veja os horários de traslado na quarta-feira dia 23/11/2022"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.textEventoDiaQuatro'),
                }}
              ></div>
            </HashLink>
            {/* <HashLink
              to={`/${lang}/${t('slugs.traslado')}#Caravanas`}
              className="btn btn-block btn-secondary"
            >
              Caravanas
            </HashLink> */}
          </div>
        </div>
      </Secao>

      <Secao className="mb-4" id="Domingo">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaUm'),
              }}
            ></h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>
        <div className="row">
          <div className="col-sm-6">
            <h2>{'POA > GRAMADO'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaUm'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaUm'),
              }}
            ></p>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </Secao>

      {/* <Secao className="mb-4" id="Segunda">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaDois'),
              }}
            ></h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>
        <div className="row">
          <div className="col-sm-6">
            <h2>{'POA > GRAMADO'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaDois'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaDois'),
              }}
            ></p>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </Secao> */}

      {/* <Secao className="mb-4" id="Terca">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaTres'),
              }}
            ></h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>
        <div className="row">
          <div className="col-sm-6">
            <h2>{'POA > GRAMADO'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaTres'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaTres'),
              }}
            ></p>
          </div>

          <div className="col-sm-6">
            <h2>{'GRAMADO > POA'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaTresV'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaTresV'),
              }}
            ></p>
          </div>
        </div>
      </Secao> */}

      <Secao className="mb-4" id="Quarta">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaQuatro'),
              }}
            ></h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-6">
            {/* <h2>{'POA > GRAMADO'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaQuatro'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaQuatro'),
              }}
            ></p> */}
          </div>

          <div className="col-sm-6">
            <h2>{'GRAMADO > POA'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaQuatroV'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaQuatroV'),
              }}
            ></p>
          </div>
        </div>
      </Secao>

      {/* <Secao className="mb-4" id="Quinta">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textEventoDiaCinco'),
              }}
            ></h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="row">
          <div className="col-sm-6">
            <h2>{'GRAMADO > POA'}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textTitleDescriptionDiaCinco'),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textDescriptionDiaCinco'),
              }}
            ></p>
          </div>
          <div className="col-sm-6"></div>
        </div>
      </Secao> */}

      <Secao className="mb-4" id="Valores">
        <SecaoCabecalho className="mb-5">
          <SecaoTitulo>
            <h1
              dangerouslySetInnerHTML={{
                __html: t('paginas.traslado.textBotaoValores'),
              }}
            ></h1>
          </SecaoTitulo>
          <SecaoMenu>
            <SecaoMenuNav>
              <SecaoMenuLista>
                <SecaoMenuItem></SecaoMenuItem>
                <SecaoMenuItem>
                  <SecaoBotaoVoltar />
                </SecaoMenuItem>
              </SecaoMenuLista>
            </SecaoMenuNav>
          </SecaoMenu>
        </SecaoCabecalho>

        <div className="container-valores">
          <div className="row">
            <div className="col-md-9">
              <div
                className="texto"
                dangerouslySetInnerHTML={{
                  __html: t('paginas.traslado.descriptionValor'),
                }}
              ></div>
              <div className="row">
                <div className="col-md-4">
                  <div className="box-ticket">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleTicketUnico'),
                      }}
                    ></h2>
                    <img src={iconBussTraslado} alt="Valores de Traslados" />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleDescriptionUnico'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoTicketUnico'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                    <p className="observacao"></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-ticket">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.comboTicketUnico'),
                      }}
                    ></h2>
                    <img src={iconTwoBussTraslado} alt="Valores de Traslados" />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.comboDescriptionUnico'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoComboTicketUnico'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                    <p
                      className="observacao"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.observacaoIdaVolta'),
                      }}
                    ></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-ticket bg-secondary">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleTicketAvulso'),
                      }}
                    ></h2>
                    <img
                      src={iconBussTowerTraslado}
                      alt="Valores de Traslados"
                    />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.descriptionTicketAvulso'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoTicketAvulso'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                    <p
                      className="observacao"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.observacaoTicketAvulso'),
                      }}
                    ></p>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="box-ticket bg-secondary">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.titleComboFeira'),
                      }}
                    ></h2>
                    <img
                      src={iconBussTowerTraslado}
                      alt="Valores de Traslados"
                    />
                    <p
                      className="texto"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.descriptionComboFeira'),
                      }}
                    ></p>
                    <p
                      className="price"
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.precoComboFeira'),
                      }}
                    ></p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('paginas.traslado.porPessoa'),
                      }}
                    ></p>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <p
                    className="texto"
                    dangerouslySetInnerHTML={{
                      __html: t('paginas.traslado.observationBoxValue'),
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="container-button">
                <div className="box-comprar">
                  <a
                    href={linkWhatsCompra}
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{
                      __html: t('paginas.traslado.textCompreAqui'),
                    }}
                  ></a>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('paginas.traslado.textTrasnporteOficial'),
                  }}
                ></p>
                <img
                  src={transporteOficialPlanalto}
                  alt="Trasnporte Oficial do Evento"
                  className="max-height-60"
                />
              </div>
            </div>
          </div>
        </div>
      </Secao>
      <VideoStyled>
        <Modal
          size="md"
          isOpen={modal}
          centered
          toggle={toggle}
          className="box-container"
        >
          <ModalBody>
            <ButtonClose onClick={toggle}>
              <ImCross />
            </ButtonClose>
            <div>
              <p>
                <strong>TRASLADOS</strong>
              </p>
              <p> EM BREVE MAIORES INFORMAÇÕES </p>
            </div>
          </ModalBody>
        </Modal>
      </VideoStyled>
    </div>
  );
};

export default Traslado;
